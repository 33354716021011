<template>
  <div class="app-container">
    
    <!-- 顶部按钮 -->
    <div class="top_btns clearfix">
      <div class="left_search fl">
        <el-button class="filter-item" type="success" size="small" @click="goback()" plain   >
          <el-icon><Back /></el-icon><span  > 返回</span>
        </el-button>
        <el-input placeholder="请输入姓名查询" v-model="listQuery.name" style="width: 200px;" class="filter-item" size="small" />
        <el-button class="filter-item" @click = chaxunname() type="primary" size="small"   >
          <el-icon><Search /></el-icon><span  > 查询</span>
        </el-button>

        <el-select class="filter-item" v-model="listQuery.feeType"  clearable placeholder="费用类型" @change = chaxunname() style="width:200px;" filterable size="small">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="right_btns fr">
        <qzf-button button_code="xc_gzb_tj" type="success" size="small" @success="addPersonal"   >
          <el-icon><CirclePlus /></el-icon><span  > 添加</span>
        </qzf-button>
        <qzf-button button_code="xc_gzb_tj" type="primary" size="small" @success="allShow"   >
          <el-icon><Tickets /></el-icon><span  > 批量选择费用类型</span></qzf-button>
        <qzf-import-back @success="getList()" from="薪酬"></qzf-import-back>
        <el-dropdown style="margin-left: 6px;" size="small">
          <qzf-button jz="false" button_code="xc_gzb_tj" type="primary" size="small">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </qzf-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="a" @click="printingA4()">
                <span>打印A4</span>
              </el-dropdown-item>
              <el-dropdown-item command="b" @click="printingA5()">
                <span>打印凭证纸</span>
              </el-dropdown-item>
              <el-dropdown-item command="c" @click="GetInportListClick()">
                <span>导出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <!-- 顶部按钮 end -->
    <!-- 内容 -->
    <el-table stripe :data="listtable" v-loading="this.isLoading" :height="this.contentStyleObj" @selection-change="handleSelectionChange" style="width: 100%; margin-top: 10px;" show-summary>
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" type="selection" width="55"> </el-table-column>
      <el-table-column align="center" type="index" fixed width="70" label="序号"> </el-table-column>
      <el-table-column prop="name" label="姓名" fixed width="100" align="center"></el-table-column>
      <el-table-column label="费用类型" width="160" align="center">
        <template #header>
          <span>费用类型</span>
            <i @click="selectAllMoney('feeType')" class="iconfont icon-piliangcaozuo"></i>
        </template>
        <template #default="{row}">
          <el-select v-model="row.feeType" placeholder="" class="el-select" filterable size="small">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="应发工资" align="center">
          <el-table-column align="center" label="工资" width="120" prop="salary">
            <template #header>
              <span>工资</span>
                <i @click="selectAllMoney('salary')" class="iconfont icon-piliangcaozuo"></i>
            </template>
            <template #default="{row}">
              <qzf-input v-model:num="row.salary" class="filter-item" size="small" style="width: 100px" />
            </template>
          </el-table-column>
          <el-table-column align="center" label="福利费" width="120" prop="subsidy">
            <template #header>
              <span>福利费</span>
                <i @click="selectAllMoney('subsidy')" class="iconfont icon-piliangcaozuo"></i>
            </template>
            <template #default="{row}">
              <qzf-input v-model:num="row.subsidy" class="filter-item" size="small" style="width: 100px" />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column align="center" label="本期免税收入" width="120" prop="mssr">
          <template #default="{row}">
            <qzf-input v-model:num="row.mssr" class="filter-item" size="small" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column label="代扣社保" align="center">
          <el-table-column align="center" prop="deductSbPension" label="养老保险" width="120">
            <template #header>
              <span>养老保险</span>
                <i @click="selectAllMoney('deductSbPension')" class="iconfont icon-piliangcaozuo"></i>
            </template>
            <template #default="{row}">
              <qzf-input v-model:num="row.deductSbPension" class="filter-item" size="small" style="width: 100px" />
            </template>
          </el-table-column>
          <el-table-column align="center" prop="deductSbMedical" label="医疗保险" width="120">
            <template #header>
              <span>医疗保险</span>
                <i @click="selectAllMoney('deductSbMedical')" class="iconfont icon-piliangcaozuo"></i>
            </template>
            <template #default="{row}">
              <qzf-input v-model:num="row.deductSbMedical" class="filter-item" size="small" style="width: 100px" />
            </template>
          </el-table-column>
          <el-table-column align="center" prop="deductSbUnemployment" label="失业保险" width="120">
            <template #header>
              <span>失业保险</span>
                <i @click="selectAllMoney('deductSbUnemployment')" class="iconfont icon-piliangcaozuo"></i>
            </template>
            <template #default="{row}">
              <qzf-input v-model:num="row.deductSbUnemployment" class="filter-item" size="small" style="width: 100px" />
            </template>
          </el-table-column>
          <el-table-column align="center" prop="deductSbIllness" label="大病医疗" width="120">
            <template #header>
              <span>大病医疗</span>
                <i @click="selectAllMoney('deductSbIllness')" class="iconfont icon-piliangcaozuo"></i>
            </template>
            <template #default="{row}">
              <qzf-input v-model:num="row.deductSbIllness" class="filter-item" size="small" style="width: 100px" />
            </template>
          </el-table-column>
          <el-table-column align="center" prop="deductSbTotal" label="合计" width="120">
            <template #default="{row}">
              <span>{{(row.deductSbPension + row.deductSbMedical + row.deductSbUnemployment + row.deductSbIllness).toFixed(2)}}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column align="center" prop="deductHouseFund" label="代扣公积金" width="120">
          <template #default="{row}">
            <qzf-input v-model:num="row.deductHouseFund" class="filter-item" size="small" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="childrenEducation" label="累计子女教育" width="120">
          <template #default="{row}">
            <qzf-input v-model:num="row.childrenEducation" class="filter-item" size="small" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="continuingEducation" label="累计继续教育" width="120">
          <template #default="{row}">
            <qzf-input v-model:num="row.continuingEducation" class="filter-item" size="small" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="seriousIllness" label="累计大病医疗" width="120">
          <template #default="{row}">
            <qzf-input v-model:num="row.seriousIllness" class="filter-item" size="small" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="housingLoan" label="累计住房贷款" width="120">
          <template #default="{row}">
            <qzf-input v-model:num="row.housingLoan" class="filter-item" size="small" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="housingRent" label="累计住房租金" width="120">
          <template #default="{row}">
            <qzf-input v-model:num="row.housingRent" class="filter-item" size="small" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="support" label="累计赡养老人" width="120">
          <template #default="{row}">
            <qzf-input v-model:num="row.support" class="filter-item" size="small" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="babyReduction" label="累计婴幼儿照护费用" width="150">
          <template #default="{row}">
            <qzf-input v-model:num="row.babyReduction" class="filter-item" size="small" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="deductOther" label="其他扣除" width="120">
          <template #header>
            <span>其他扣除</span>
              <i @click="selectAllMoney('deductOther')" class="iconfont icon-piliangcaozuo"></i>
          </template>
          <template #default="{row}">
            <qzf-input v-model:num="row.deductOther" class="filter-item" size="small" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="deductIncomeTax" label="本期代扣个税" width="120">
          <template #default="{row}">
            <qzf-input v-model:num="row.deductIncomeTax" class="filter-item" size="small" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="actualSalary" label="实发工资" width="120">
          <template #default="{row}">
            <qzf-input v-model:num="row.actualSalary" class="filter-item" size="small" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="remark" label="备注" width="120">
          <template #default="{row}">
            <el-input placeholder="" v-model="row.remark" class="filter-item" size="small" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="120">
          <template #default="{row}">
            <el-tooltip content="保存" placement="top">
              <i class="iconfont icon-baocun" style="cursor: pointer;" @click="savadd(row)"></i>
            </el-tooltip>
            <el-tooltip content="删除" placement="top">
              <i class="iconfont icon-shanchu" @click="handleModifyStatus(row)"></i>
            </el-tooltip>
          </template>
        </el-table-column>
    </el-table>
    <div class="saveAll">
      <qzf-button button_code="xc_gzb_tj" v-loading.fullscreen.lock="fullscreenLoading" type="success" size="small" @success="saveAll()" >
        <el-icon size="14"><Finished /></el-icon><span  > 批量保存</span>
      </qzf-button>
      <qzf-button button_code="xc_gzb_tj" v-loading.fullscreen.lock="fullscreenLoading" type="danger" size="small" @success="delAll()" plain>
        <el-icon size="14"><Delete /></el-icon> <span  > 批量删除</span>
      </qzf-button>
    </div>
    <!-- 内容 end -->
    <!-- 批量选择费用类型 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="费用类型" v-model="dialogFormVisibleall">
      <el-form :model="form">
        <el-form-item label="费用类型" :label-width="formLabelWidth">
          <el-select v-model="types" placeholder="" style="width:200px;" class="el-select" filterable size="small">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisibleall = false" size="small">取 消</el-button>
          <el-button type="primary" @click="typeClick()" size="small">确 定</el-button>
        </div>
      </template>
      
    </el-dialog>
    <addNormal ref="addNormal" @success="getList" :type="'正常工资薪资'"></addNormal>
    <print-setting ref="printSetting" @success="printSettingA5"></print-setting>
  </div>

  <!-- <qzf-button button_code="xc_gzb_tj" v-loading.fullscreen.lock="fullscreenLoading" class="saveAll" type="success" size="small" @success="saveAll()" plain>批量保存</qzf-button> -->
</template>

<script>
import addNormal from './components/addNormal.vue'
import { eaSalaryList,saveEaSalary,delSalary } from '@/api/salary.js'
import { exportSalary } from "@/api/export"
import { printSalary } from "@/api/printSet"

export default {
  components: {
    addNormal
  },
  data() {
    return {
      listtable: [],
      addall:[],
      dialogFormVisibleall: false,
      types: "",
      listQuery: {
        // page: 1,
        // limit: 20,
        sheetId: this.$store.getters["commons/params"].salaryNormalId,
        name:'',
        feeType:''
      },
      isLoading:false,
      fullscreenLoading:false,
      allids:[],
      options: [
        {
          value: "管理费用",
          label: "管理费用"
        },
        {
          value: "生产费用",
          label: "生产成本"
        },
        {
          value: "制造费用",
          label: "制造费用"
        },
        {
          value: "销售费用",
          label: "销售费用"
        },
        {
          value: "劳务成本",
          label: "劳务成本"
        },
        {
          value: "其他",
          label: "其他"
        },
      ],
    }
  },
  created() {
    this.contentStyleObj= this.$getHeight(280)
    this.getList()
  },
  methods: {
    goback() {
      this.$router.push({
        path: "/input/remuneration/remuneration",
        name: "remuneration"
      });
    },
    changeChecked(e){
      if(e){
        this.listtable.map(v=>{v.checked = true})
      }else{
        this.listtable.map(v=>{v.checked = false})
      }
      this.chulid()
    },
    handleSelectionChange(e) {
      this.allids = e.map(v=>{
        return {id:v.id}
      })
    },
    chulid(){
      let arr = []
      this.listtable.map(v=>{
        if(v.checked){
          arr.push(v.id*1)
        }
      })
      this.allids = arr;
    },
    changeOneChecked(){
      this.chulid()
    },
    //批量选择费用类型
    allShow(){
      if(this.allids.length != 0){
        this.dialogFormVisibleall = true
      }else{
        this.$message.error('请至少选择一条');
      }
    },
    //批量选择费确定
    typeClick(){
      this.dialogFormVisibleall = false
      this.listtable.map(item=>{
        this.allids.map(v=>{
          if(v.id == item.id){
            item.feeType = this.types
          }
        })
      })
    },
    // 保存
    savadd(row) {
      row.edit = false;
      row.salary = row.salary * 1;
      row.deductSbPension = row.deductSbPension * 1;
      row.deductSbMedical = row.deductSbMedical * 1;
      row.deductSbUnemployment = row.deductSbUnemployment * 1;
      row.deductSbIllness = row.deductSbIllness * 1;
      row.deductSbTotal = row.deductSbTotal * 1;
      row.deductHouseFund = row.deductHouseFund * 1;
      row.deductOther = row.deductOther * 1;
      row.deductIncomeTax = row.deductIncomeTax * 1;
      row.actualSalary = row.actualSalary * 1;
      row.subsidy = row.subsidy * 1;
      saveEaSalary([row]).then(res => {
        if(res.data.msg == "success") {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.getList()
        }
      })
    },
    //删除
    handleModifyStatus(e) {
      delSalary([{id: e.id}]).then(res => {
        if(res.data.msg == "success") {
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          this.getList()
        }
      })
    },
    //批量删除
    delAll() {
      if(this.allids.length == 0) {
        this.$qzfMessage("请至少选择一家公司",1)
        return
      }
      delSalary(this.allids).then(res => {
        if(res.data.msg == "success") {
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          this.getList()
        }
      })
    },
    //批量保存
    saveAll() {
      this.listtable.map(v=>{
        v.salary = Number(v.salary)
        v.subsidy = Number(v.subsidy)
        v.deductSbPension = Number(v.deductSbPension)
        v.deductSbMedical = Number(v.deductSbMedical)
        v.deductSbUnemployment = Number(v.deductSbUnemployment)
        v.deductSbIllness = Number(v.deductSbIllness)
        v.deductHouseFund = Number(v.deductHouseFund)
        v.childrenEducation = Number(v.childrenEducation)
        v.continuingEducation = Number(v.continuingEducation)
        v.seriousIllness = Number(v.seriousIllness)
        v.housingLoan = Number(v.housingLoan)
        v.housingRent = Number(v.housingRent)
        v.support = Number(v.support)
        v.babyReduction = Number(v.babyReduction)
        v.deductOther = Number(v.deductOther)
        v.deductIncomeTax = Number(v.deductIncomeTax)
        v.actualSalary = Number(v.actualSalary)
      })
      this.fullscreenLoading = true
      saveEaSalary(this.listtable).then(res => {
        this.fullscreenLoading = false
        if(res.data.msg != "success"){
          this.$message.error(res.data.msg);
        }else{
          this.$message({
            message: "保存成功",
            type: "success"
          });
        }
        this.$router.push({
          path: "/input/remuneration/remuneration",
          name: "remuneration"
        });
        this.$bus.emit('salarySheetUpdate')
      });
    },
    getList() {
      this.isLoading = true
      eaSalaryList(this.listQuery).then((res) => {
        this.isLoading = false

        this.listtable = res.data.data.list?res.data.data.list:[]
      });
    },
    //新增
    addPersonal() {
      this.$refs.addNormal.openDialog()
    },
    selectAllMoney(e){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(!this.listtable[0]){
        return
      }
      this.listtable.map(v=>{
        v[e] = this.listtable[0][e]
      })
    },
    chaxunname(){
      this.getList({ name:this.cnName,feeType:this.feeType });
    },
    GetInportListClick(){
      let param = {
        period:this.$store.getters['user/comInfo'].period,
        comId:this.$store.getters['user/comInfo'].comId*1,
        type:'salary'
      }
      exportSalary(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    printingA4(){
      let paramA4 = {
        paperType:'A4-S',
        bookNames : ['salray'],
        type:'salary',
        comIds:[this.$store.getters['user/comInfo'].comId*1],
        period:this.$store.getters['user/comInfo'].period,
        query:this.listQuery
      }
      printSalary(paramA4).then(res => {
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    printingA5(){
      this.$refs.printSetting.dayinCustomStatus = true
    },
    printSettingA5(params){
      params.bookNames = ['salray']
      params.query = this.listQuery
      params.type = 'salary'
      printSalary(params).then(res => {
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
i{
  margin-left: 4px;
}
.saveAll {
  position: absolute;
  right: 20px;
  bottom: 70px;
  z-index: 2004;
}
.zcgzxc { 
    margin-top: 10px;
}
.zcgzxc  th {
    color: rgb(51, 51, 51);
    font-size: 14px;
}
table{
  table-layout: fixed;
}
.content{
  .ss{
    position:sticky;
      left:0; /* 首行永远固定在左侧 */
      z-index:1;
  }
  // width: 2500px;
  // margin: 0 auto;
  border:1px solid #c2c2c2 !important;
  border-bottom: none;
  border-right: none;
  border-collapse:collapse;
  .fixed_td{
    position: relative;
    td:nth-child(3){
      position:sticky;
      left:0; /* 首行永远固定在左侧 */
      z-index:1;
      border-left:1px solid #c2c2c2;
      &:after {
        width: 1px;
        background: #c2c2c2;
        content: " ";
        clear: both;
        height: 100%;
        position: absolute;
        right: -1px;
        top: 0;
      }
      &:before {
        width: 1px;
        background: #c2c2c2;
        content: " ";
        clear: both;
        height: 100%;
        position: absolute;
        left: -1px;
        top: 0;
      }
    }
  }
  
  span{
    line-height: 34px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
    background: #fff;
    
  }
  td{
    // width: 200px;
    border-right:1px solid #c2c2c2 !important;
    border-bottom:1px solid #c2c2c2 !important;
    line-height: 25px;
    padding:0 2px;
    color: #666;
    font-size: 14px;
    background: #fff;
  }
  
  input{
    width: 90%;
    line-height: 34px;
    padding: 6px 0 6px 6px;
    font-size: 14px;
  }
}
  i{
    // font-size: 8px;
    line-height: 8px;
    text-align: center;
    // color: $color-main;
    // font-size: 18px;
    // padding-right: 5px;
  }
  .icon-baocun,.icon-shanchu{
    color: $color-main;
    font-size: 18px;
    padding-right: 5px;
  }
.top_bg{
  td{
    text-align: center;
  }
  .icon-tongbu1{
    display:inline-block;
    font-size: 16px;
    color: #67c23a;
    line-height: 26px;
    transform: translateY(2px);
  }
}
.center{
  text-align: center;
}
</style>
<style lang="scss" scoped>

.el-icon-document,.iconfont icon-bianji,.iconfont icon-shanchu {
  margin: 0 5px;
  cursor: pointer;
  color: $color-main;
  font-size: 18px;
  line-height: 24px;
}
</style>
<style>
.el-input--small .el-input__inner {
  height: 28px;
  line-height: 28px;
}
.el-input--medium .el-input__inner {
  height: 28px;
  line-height: 28px;
}
.xiaxuan{
  cursor: pointer;
  color: black;
}
.filter-item {
  margin-right: 10px;
}

.table-head{ width: 1800px; background-color:#999;color:#000;}
/* .table-body{ width: 1800px; height:500px;overflow:scroll;} */
.table-body{ width: 1800px; height:500px;}
</style>